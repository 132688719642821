import React from 'react';
import { dot } from '../../lib/obj';

const BLD_TYPE = { type: "build", item: { type:"buildItem" }, items: { type:"buildItem" }};
const CIT_TYPE = { type: "collectionItem" };
const COL_TYPE = { type: "collection", item: { type:"collectionItem" }, items: { type:"collectionItem" }};
const FLE_TYPE = { type: "file"};
const GRP_TYPE = { type: "group", item: { type:"groupItem" }, items: { type:"groupItem" }};
const LOC_TYPE = { type: "location", item: { type:"locationItem" }, items: { type:"locationItem" }};
const LIT_TYPE = { type: "locationItem" };
const ORD_TYPE = { type: "order", item: { type:"orderItem" }, items: { type:"orderItem" }};
const ORN_TYPE = { type: "orderReturn"};
const ORS_TYPE = { type: "orderResolution"};
const POR_TYPE = { type: "po", item: { type:"poItem" }, items: { type:"poItem" }, file:FLE_TYPE, files: FLE_TYPE, payment: "payment", payments: "payment" };
const PRT_TYPE = { type: "part"};
const PAY_TYPE = { type: "payment"};
const PRD_TYPE = { type: "product", part: { type:"productPart" }, parts: { type:"productPart" }, item: { type:"productItem" }, items: { type:"productItem" },file:FLE_TYPE, files: FLE_TYPE };
const PIT_TYPE = { type: "productItem" };
const QRY_TYPE = { type: "query" };
const STB_TYPE = { type: "stub" };
const TRN_TYPE = { type: "transfer", item: { type:"transferItem" }, items: { type:"transferItem" }};
const USR_TYPE = { type: "user" };
const VND_TYPE = { type: "vendor", item: { type:"vendorItem" }, items: { type:"vendorItem" }};
const VNI_TYPE = { type: "vendorItem" };
export const TYPE_MAP = {
  build: BLD_TYPE,
  builds: BLD_TYPE,
  collection: COL_TYPE,
  collections: COL_TYPE,
  'collection-item': CIT_TYPE,
  'collection-items': CIT_TYPE,
  file: FLE_TYPE,
  files: FLE_TYPE,
  group: GRP_TYPE,
  groups: GRP_TYPE,
  location: LOC_TYPE,
  locations: LOC_TYPE,
  'location-item': LIT_TYPE,
  'location-items': LIT_TYPE,
  order: ORD_TYPE,
  orders: ORD_TYPE,
  'order-resolution': ORS_TYPE,
  'order-resolutions': ORS_TYPE,
  'order-return': ORN_TYPE,
  'order-returns': ORN_TYPE,
  part: PRT_TYPE,
  parts: PRT_TYPE,
  payment: PAY_TYPE,
  payments: PAY_TYPE,
  po: POR_TYPE,
  pos: POR_TYPE,
  'po-payment': { type: "poPayment"},
  'po-payments': { type: "poPayment"},
  product: PRD_TYPE,
  products: PRD_TYPE,
  'product-item': PIT_TYPE,
  'product-items': PIT_TYPE,
  query: QRY_TYPE,
  queries: QRY_TYPE,
  stubs: STB_TYPE,
  transfer: TRN_TYPE,
  transfers: TRN_TYPE,
  user: USR_TYPE,
  users: USR_TYPE,
  vendor: VND_TYPE,
  vendors: VND_TYPE,
  'vendor-items': VNI_TYPE
};


/**
 * Determines the singular type for the given options
**/
export const singularType = (opts = {})=>{
  if (opts.childType) {
    return dot(TYPE_MAP,[opts.type,opts.childType,'type']);
  }
  if (opts.type) {
    return dot(TYPE_MAP,[opts.type,'type'])
  }
}
