import React from 'react';
import { Prompt } from 'react-router';
import { connect } from 'react-redux'
import Page from './Page'
import RectButton from '../elements/RectButton';
import PlainButton from '../elements/PlainButton';
import Section from '../elements/Section';
import Row from '../elements/Row';
import Readout from '../elements/Readout';
import Separator from '../elements/Separator';
import OrderForm from '../forms/OrderForm';
import OrderItemForm from '../forms/OrderItemForm';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import DropControl from '../controls/DropControl';
import Draft from '../../lib/draft.js';

import LoadableArea from '../areas/LoadableArea';
import PaddedArea from '../areas/PaddedArea';
import HeaderLine from '../layouts/HeaderLine';
import ItemLine from '../layouts/ItemLine';
import NameVal from '../containers/NameVal';
import TypeVal from '../containers/TypeVal';
import SubVal from '../containers/SubVal';
import MoneyVal from '../containers/MoneyVal';
import QtyVal from '../containers/QtyVal';
import StateVal from '../containers/StateVal';
import DateVal from '../containers/DateVal';
import ActionVal from '../containers/ActionVal';
import TitleVal from '../containers/TitleVal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { financial } from '../../lib/formats';
import { docForLoc, docHasEdits } from '../../lib/inventory-util';
import { dot, hasKeys } from '../../lib/obj';

import { loadItem, listItems, pushItems, deleteItem, startDraftItem, setItemAction } from '../../actions/InventoryActions';
import { openPopup } from '../../actions/PopupActions';

import { PRODUCT_SEARCH } from '../../constants/inventory/Product'

import './Page.css';
import './OrderPage.css';

class OrderPage extends Page {

  componentWillMount() {
    let id = this.props.id;
    if (id && id !== "new") {
      this.props.loadItem({type:"orders",id:id});
      this.props.listItems({type:"orders",id:id,childType:"items"});
    }
  }

  onSaveOrder (evt, history) {

    // 1st, attempt to push the Order changes
    let order = this.props.order;
    this.props.pushItems([{type:"orders",
      id: this.props.id,
      docs: {order:order},
      action: order.action
    }]).then((res)=>{

      // Get th epo id
      let resOrder = dot(res,"0.order");
      let id = dot(resOrder,"data.id") || this.props.id;

      // Add the items to the array
      let items = dot(this.props.order, "items.list.docs") || [];
      let arr = items.map((item, index)=>{
        let itemId = dot(item,"data.id");
        let version = dot(item,"data.version");
        let action = item.action ? item.action : (version >= 1 ? undefined : "create" );
        return {
          type:"orders",
          id: id != "new" ? id : undefined,
          childType: "items",
          childId: itemId != "new" ? itemId : undefined,
          docs:{orderItem:item},
          action: action,
          index: index
        };
      });

      // 2nd, push the item changes
      this.props.pushItems(arr).then((res)=>{
        // Check if page needs to be reloaded
        if (dot(resOrder,"data.version") == 1) {
          history.replace(`/orders/${id}`);
        }
        else {
          this.props.loadItem({type:"orders",id:id});
          this.props.listItems({type:"orders",id:id,childType:"items"});
        }
      });
    });
  }

  needsSave() {
    // Check po for sets
    if( hasKeys(dot(this.props.order,"sets")) ) {
      return true;
    }

    // Check if any of the items have changes
    let items = dot(this.props.order, "items.list.docs") || [];
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if( docHasEdits(item) ) {
        return true;
      }
    }
    return false;
  }

  onCancel(evt,history) {
    history.goBack();
  }

  onDelete(evt,history) {
    this.props.openPopup({name:"Confirm",
      props:{
        title: "Delete Order",
        message: "Are you sure you want to delete this Order? You will NOT be able to undo this action.",
        onOk:()=>{
          this.props.deleteItem({type:"orders", id: this.props.id}).then(()=>{
            history.replace("/orders");
          });
        },
        okTheme: "red",
        okLabel: "Delete Order"
    }});
  }

  onAddItem() {
    this.props.openPopup({name:"Search",
      props:{
        title: "Select products.",
        type: "products",
        query: PRODUCT_SEARCH,
        renderItem: (item, index)=>{
          return [
            <NameVal key="name">{dot(item,"data.name")}</NameVal>,
            <SubVal key="sku">{dot(item,"data.sku") || "-"}</SubVal>,
            <MoneyVal key="price" suffix={<SubVal>msrp</SubVal>}>{dot(item,"data.msrp") || "0"}</MoneyVal>,
            <TypeVal key="type" type="products">{dot(item,"data.type")}</TypeVal>
          ]
        },
        other: {
          label: "+ New Product",
          onClick: () => {
            this._createProduct();
          }
        },
        onOk:(items)=>{
          this._addItems(items)
        }
    }});
  }

  _createProduct() {
    let id = this.props.id;
    this.props.openPopup({
      name:"ItemEdit",
      props: {
        title: "New Product",
        opts: {
          type:"products",
          id: "new"
        },
        onPush:(product)=>{
          this._addItems([product]);
        }
      }
    });
  }

  _addItems(items) {
    let existing = dot(this.props.order, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;

    // Start draft items for each of the new items
    items.forEach((item, index)=>{
      let data = item.data || {};
      this.props.startDraftItem({
        type:"orders",
        id: id,
        childType: "items",
        index: count + index,
        data: {
          orderId: id,
          type: data.type,
          itemId: data.id,
          item: data
        }
      });
    });
  }

  onAddLine() {
    let existing = dot(this.props.order, "items.list.docs") || [];
    let count = existing.length;
    let id = this.props.id;
    this.props.startDraftItem({
      type:"orders",
      id: id,
      childType: "items",
      index: count,
      data: {
        orderId: id
      }
    });
  }

  render() {
    let id = this.props.id;
    let isNew = !id || id === "new";

    // Check if we need to save
    let needsSave = this.needsSave();

    // Get handle to the order Items
    let items = dot(this.props.order, "items.list.docs") || [];
    let itemsStatus = dot(this.props.order, "items.list.status");

    // Check to see if sending order
    let status = dot(this.props.order, "status");
    let isSending = status == "saving" || status == "creating";
    if (!isSending) {
      for (var i = 0; i < items.length; i++) {
        status = dot(items[i],"status");
        isSending = status == "saving" || status == "creating";
        if (isSending) { break; }
      }
    }

    // Setup buttons
    let cancelButton = null;
    let saveButton = null;
    if (isNew) {
      cancelButton = <RectButton theme="bw" onClick={(evt,history)=>{this.onCancel(evt,history)}}>Cancel</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSaveOrder(evt, history)}}>
        {isSending ? "Creating" : "Create"}
      </RectButton>;
    }
    else {
      cancelButton = <RectButton theme="red" onClick={(evt,history)=>{this.onDelete(evt,history)}}>Delete</RectButton>;
      saveButton = <RectButton theme="blue" working={isSending} onClick={(evt,history)=>{this.onSaveOrder(evt, history)}} lock={!needsSave}>
        {isSending ? "Saving" : "Save"}
      </RectButton>;
    }

    return (
      <div className="Page OrderPage">
        <Prompt
          when={needsSave}
          message='Are you sure you want to leave? You have unsaved changes.'
        />
        <PageHeaderOutline title={"View Order"} aside={saveButton} top={this._backButton()}></PageHeaderOutline>
        <Section key="details" shout="Details">
          <HeaderLine><div>Items</div></HeaderLine>
          <LoadableArea working={status=="loading"}>
            <OrderForm order={this.props.order}/>
          </LoadableArea>
        </Section>
        <Section key="items">
          <HeaderLine><div>Items</div></HeaderLine>
          <LoadableArea working={itemsStatus=="loading"}>
            {items.map((item, index)=>{
              return <div className="Item" key={`item${index}`}>
                <OrderItemForm orderItem={item} listIndex={index}/>
              </div>
            })}
            <PaddedArea>
              <Row align="right">
                <PlainButton theme="blue" layout="auto" onClick={()=>{this.onAddLine();}}>Add line</PlainButton>
                <span style={{color:"#DDD"}}>|</span>
                <PlainButton theme="blue" layout="auto" onClick={()=>{this.onAddItem();}}>Add items</PlainButton>
              </Row>
            </PaddedArea>
          </LoadableArea>
        </Section>
        <Separator/>
        <Row align="space-between">
          {cancelButton}
          {saveButton}
        </Row>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let order = dot(state.inventory,["orders",props.id]) || {};
  return {
    order: order,
    inventory: state.inventory
  }
};

const mapDispatch = (dispatch) => {
  return {
    loadItem: opts => dispatch(loadItem(opts)),
    listItems: opts => dispatch(listItems(opts)),
    deleteItem: opts => dispatch(deleteItem(opts)),
    pushItems: arr => dispatch(pushItems(arr)),
    startDraftItem: opts => dispatch(startDraftItem(opts)),
    setItemAction: opts => dispatch(setItemAction(opts)),
    openPopup: opts => dispatch(openPopup(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(OrderPage)
